// Digest realm="FlexRealm", nonce="db34cb20d445e9639e1f36f55aa95376", qop="auth"
export class KeyValuePair {

    key: string;
    value: string;

    constructor (k: string, v: string) {
        this.key = k;
        this.value = v;
    }
}

export function parseMultiPartString(digest: string): Array<KeyValuePair> {

    digest = digest.replace(/Digest /i, '').replace(/\s*/g, '');

    let kvpArray = digest.split(',');
    let result: Array<KeyValuePair> = [];

    function fillResult(v:string, a:Array<KeyValuePair>) {
        let tmp = v.split('=');
        a.push(new KeyValuePair(tmp[0], tmp[1].replace(/"/g, '')))
    }

    kvpArray.forEach(v => fillResult(v, result));

    return result;
}

export function newGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    });
}

export function stringNullOrEmpty(str: any): boolean {
    return (str === null || str === undefined || str === '');
}