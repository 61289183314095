import {Injectable, ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {RouteGuardService} from "../../services/route-guard.service";
import {IKeyValue} from "../../interfaces/general";

export const routes: Routes = [
    /**
     * OPEN ROUTES
     * these routes MUST NOT use RouteGuardService
     */
    {path: 'pa', loadChildren: 'app/modules/pre-auth/pre-auth.module#PreAuthModule'},
    {path: 'not-authorized', loadChildren: 'app/modules/warnings/warnings.module#WarningsModule'},
    /**
     * SECURED ROUTES - always use {@link RouteGuardService}
     */
    {path: '', loadChildren: 'app/modules/index/index.module#IndexModule', pathMatch: 'full', canActivate: [RouteGuardService]},
    {path: 'translations', data: { developerOnly: true }, loadChildren: 'app/modules/translations/translations.module#TranslationModule', canActivate: [RouteGuardService]},
    {path: 'articles', loadChildren: 'app/modules/articles/articles.module#ArticlesModule', canActivate: [RouteGuardService]},
    {path: 'parameters', loadChildren: 'app/modules/parameters/parameters-administration.module#ParametersAdministrationModule', canActivate: [RouteGuardService]},
    {path: 'options', loadChildren: 'app/modules/options/options.module#OptionsModule', canActivate: [RouteGuardService]},
    {path: 'banners', loadChildren: 'app/modules/banners/banners.module#BannersModule', canActivate: [RouteGuardService]},
    {path: 'emails', loadChildren: 'app/modules/emails-administration/emails-administration.module#EmailsAdministrationModule', canActivate: [RouteGuardService]},
    {path: 'products', loadChildren: 'app/modules/products/products.module#ProductsModule', canActivate: [RouteGuardService]},
    {path: 'newsletters', loadChildren: 'app/modules/newsletters/newsletters.module#NewslettersModule', canActivate: [RouteGuardService]},
    {path: 'categories', loadChildren: 'app/modules/categories/categories.module#CategoriesModule', canActivate: [RouteGuardService]},
    {path: 'feeds', loadChildren: 'app/modules/feeds/feeds.module#FeedsModule', canActivate: [RouteGuardService]},
    {path: 'orders', loadChildren: 'app/modules/orders/orders.module#OrdersModule', canActivate: [RouteGuardService]},
    {path: 'logs', loadChildren: 'app/modules/log-viewer/log-viewer.module#LogViewerModule', canActivate: [RouteGuardService]},
    {path: 'error-pages', loadChildren: 'app/modules/error-pages/error-pages.module#ErrorPagesModule', canActivate: [RouteGuardService]},
    {path: 'coupons', loadChildren: 'app/modules/coupons/coupons.module#CouponsModule', canActivate: [RouteGuardService]},
    {path: 'shipments', loadChildren: 'app/modules/shipment-payment/shipment-payment.module#ShipmentPaymentModule', canActivate: [RouteGuardService]},
    {path: 'google-analytics', loadChildren: 'app/modules/google-analytics/google-analytics.module#GoogleAnalyticsModule', canActivate: [RouteGuardService]},
    {path: 'reviews', loadChildren: 'app/modules/reviews/reviews.module#ReviewsModule', canActivate: [RouteGuardService]},
    {path: 'rights', loadChildren: 'app/modules/rights/rights.module#RightsModule', canActivate: [RouteGuardService]},
    {path: 'sitemap', data: { developerOnly: true }, loadChildren: 'app/modules/sitemap/sitemap.module#SiteMapModule', canActivate: [RouteGuardService]},
    {path: 'customers', loadChildren: 'app/modules/customers/customers.module#CustomersModule', canActivate: [RouteGuardService]},
    {path: 'pricelists', loadChildren: 'app/modules/pricelists/pricelists.module#PriceListsModule', canActivate: [RouteGuardService]},
    {path: 'view-config', loadChildren: 'app/modules/view-config/view-config.module#ViewConfigModule', canActivate: [RouteGuardService]},
    {path: 'feed-list', loadChildren: 'app/modules/feeds-simple/feeds-simple.module#FeedsSimpleModule', canActivate: [RouteGuardService]},
    {path: 'firm-config', loadChildren: 'app/modules/firm-config/firm-config.module#FirmConfigModule', canActivate: [RouteGuardService]},
    {path: '**', redirectTo: '/chyba/404'}
];

/*
 * every route in use - except for '', '/pa' and '/not-authorized' MUST BE TRANSLATED HERE
 */
@Injectable()
export class RouteTranslationService {

    _routeTranslations: IKeyValue[];

    get routeTranslations(): IKeyValue[] {
        return this._routeTranslations;
    }

    set routeTranslations(value: IKeyValue[]) {
        this._routeTranslations = value;
    }

    constructor () {
        this.routeTranslations = [
            {key: 'all', value: 'Vše'},
            {key: 'translations', value: 'Překlady'},
            {key: 'articles', value: 'Články'},
            {key: 'parameters', value: 'Parametry'},
            {key: 'options', value: 'Nastavení'},
            {key: 'banners', value: 'Bannery'},
            {key: 'emails', value: 'Emaily'},
            {key: 'products', value: 'Produkty'},
            {key: 'newsletters', value: 'Newslettery'},
            {key: 'categories', value: 'Kategorie'},
            {key: 'feeds', value: 'Feedy'},
            {key: 'orders', value: 'Objednávky'},
            {key: 'logs', value: 'Logy'},
            {key: 'transports', value: 'Přenosy'},
            {key: 'shipments', value: 'Doprava a platba'},
            {key: 'error-pages', value: 'Chybové stránky'},
            {key: 'google-analytics', value: 'Google analytics'},
            {key: 'reviews', value: 'Recenze'},
            {key: 'rights', value: 'Práva'},
            {key: 'sitemap', value: 'Sitemap.xml'},
            {key: 'customers', value: 'Zákazníci'},
        ]
    }
}

export const appRoutingProviders: any[] = [
    RouteGuardService,
    RouteTranslationService
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);
