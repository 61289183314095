import {EventEmitter, Injectable, OnDestroy} from "@angular/core";
import {parseMultiPartString} from '../helpers/string.helper';
import {ErrorService} from "./error.service";
import {AuthInfo, DataRequest, IAdminUserRights, IEmitterMessage, ILoginResult} from "../interfaces/general";
import {loadFromSession, saveToSession} from "../helpers/cookie.helper";
import {SharedAppSettings} from "../shared-settings/shared-settings";
import {Routes} from "@angular/router";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {CredentialStorage} from "./credential-storage.service";

declare let sha256: any;
declare let moment: any;

// todo: UNIT TEST FOR THIS SERVICE

/**
 * @description
 * Service providing both login and proxying of requests for secured routes
 * Login scenario:
 * - pops-up the login dialog through emitter to {@link LoginComponent}
 * - challenges the server to get authorization data from it, e.g. realm, nonce, quop
 * - takes the credentials obtained from {@link LoginComponent}
 * - assembles Authorization HTTP header and adds it to the login request
 * - makes second call to the server
 * - stores obtained data to localStorage
 * Proxy scenario:
 * - takes input request, both GET or POST
 * - tries to retrieve authorization info from session/local
 * - assembles Authorization header and appends it to the call
 * - makes the request to the server
 * - if the call fails - which MUST be handled on the caller side, then it emits query for login route / dialog
 * */
@Injectable()
export class DigestService implements OnDestroy {

    private _developerMode: boolean;

    get developerMode(): boolean {
        return this._developerMode;
    }

    set developerMode(value: boolean) {
        this._developerMode = value;
    }

    private _userRights: IAdminUserRights;
    get userRights(): IAdminUserRights {
        return this._userRights;
    }

    set userRights(value: IAdminUserRights) {
        this._userRights = value;
        saveToSession('ur', value)
    }

    _currentRight: number;
    get currentRight(): number {
        return this._currentRight;
    }

    set currentRight(value: number) {
        this._currentRight = value;
        saveToSession('cri', value);
    }

    _securedRoutes: Routes;

    get securedRoutes(): Routes {
        return this._securedRoutes;
    }

    set securedRoutes(value: Routes) {
        this._securedRoutes = value;
    }

    nonce: string;
    realm: string;
    cnonce: string;
    userName: string;
    password: string;
    requestedUri: string;
    requestedMethod: string;
    cookieExpHours: number;

    loginStatus: EventEmitter<IEmitterMessage>;
    querySignOut: EventEmitter<IEmitterMessage>;
    userSignedOut: EventEmitter<IEmitterMessage>;

    constructor(private http: HttpClient, private errorSvc: ErrorService,
                private sapSvc: SharedAppSettings, private storage: CredentialStorage) {

        const perma = this.storage.permanent;
        let aiValid: boolean = false;

        const ai: AuthInfo = CredentialStorage.authInfo;
        if (ai) {
            const mt = moment();
            const mtValidTo = moment(ai.validTo);
            if (mtValidTo > mt) {
                aiValid = true;
            }
        }

        if (!aiValid && !perma) {
            /**
             * This means fresh start, nothing in session/local, we settle new values
             */
            this.initNotSigned();
        } else  {
            /**
             * this means there was full page reload, so we rather take stored values
             * */
            this.cnonce = ai.clientNonce;
            this.userName = ai.userName;
        }

        let ur: IAdminUserRights = loadFromSession('ur');
        if (ur) {
            this.userRights = ur;
            this.developerMode = ur.developer;
        }

        this.cookieExpHours = perma ? this.sapSvc.Defaults.PermanentLoginExpirationHours : null;

        this.loginStatus = new EventEmitter();
        this.querySignOut = new EventEmitter();
        this.userSignedOut = new EventEmitter();
    }

    private initNotSigned(): void {
        CredentialStorage.removeAuthInfo();
        this.cnonce = sha256((Math.random() * 1000000).toString());
    }

    forceLogin(): void {
        this.querySignOut.emit({type: 'info', message: 'login required'});
    }

    /**
     * @description
     * Handles HTTP communication errors for secured routes
     * First checks for HTTP status code 403 (Forbidden) and in such case forces login
     * In case of a different error code handles error further to {@link ErrorService}
     * @param err
     * @param req - passed from previous method for second try, type of {@link DataRequest}
     */
    handleError(err: HttpResponse<any>, req: DataRequest): any {
        this.errorSvc.handleError(err);
    }

    /**
     * @description
     * Tries to log the user in. If the first call does not succeed, it is repeated with Authorization header
     * assembled from the server challenge response. Login status is the returned via emitter
     * to the {@link LoginComponent} for further proceedings
     */
    login(userName: string, pwd: string): void {

        this.cookieExpHours = this.storage.permanent ? this.sapSvc.Defaults.PermanentLoginExpirationHours : null;

        this.requestedUri = this.storage.permanent ? 'api/login?permanent' : 'api/login';
        this.requestedMethod = 'GET';

        this.userName = userName;
        this.password = pwd;

        this.http.get(this.requestedUri)
            .subscribe(
                res => {this.loginStatus.emit({type: 'info', message: this.userName, data: res})},
                (err) => {
                    let headers = this.getAuthHeaderOnChallenge(err);
                    this.reGetWithAuth(this.requestedUri, headers)
                        .subscribe(
                            /**
                             * do login resultu si nechat z WEB Api poslat pole s uživatelskými tokeny a jejich
                             * příslušnými právy, toto uložit jako property "userRights: IUserRight[]" do této servisy
                             * samozřejmě definovat interface IUserRight jako kopii Web Api selectoru
                             * Použití v {@link RouteGuardService}
                             */
                            (r: ILoginResult) => {
                                let ai: AuthInfo = {
                                    a: r.A,
                                    b: r.B,
                                    c: r.C,
                                    realm: this.realm,
                                    nonce: this.nonce,
                                    clientNonce: this.cnonce,
                                    userName: this.userName,
                                    hu: r.Hu,
                                    loggedIn: true,
                                    validTo: this.storage.permanent
                                        /**
                                         * README IMPORTANT
                                         * The "-5" offset should cause user kick-off on the client side first
                                         * before the server nonce expires
                                         */
                                        ? moment().add(this.cookieExpHours - 5, 'hours').format()
                                        : moment().add(this.sapSvc.Defaults.NonceTimeMinutes, 'minutes').format(),
                                    developerMode: r.DeveloperMode
                                };

                                // getting user rights for basic security model based on routes
                                this.userRights = r.AdminUserRights;

                                this._developerMode = r.DeveloperMode;
                                CredentialStorage.authInfo = ai;
                                this.loginStatus.emit({type: 'info', message: this.userName, data: r})
                            },
                            (e) => this.loginStatus.emit({type: 'error', message: 'bad credentials, login failed', data: e})
                        )
                }
            );
    }

    /**
     * @description
     * Wipes the stored data and clears instance variables
     */
    logOut() {
        CredentialStorage.removeAuthInfo();
        this.storage.permanent = false;
        this.userName = undefined;
        this.password = undefined;
        this.userSignedOut.emit({type: 'info', message: 'user signed out'})
    }

    /**
     * @description
     * If this call fails due to invalid ai stored in session / local, then forceLogin() on this service
     * must be called from the caller's error handling branch
     * @param headers
     * @param uri
     * @returns {Observable<Response>}
     */
    private reGetWithAuth(uri: string, headers: HttpHeaders): any {
        return this.http.get(uri, {headers: headers})
    }

    /**
     * @description
     * Gets auth information (e.g. nonce, realm) from information returned in Www-Authenticate header
     * "quop" is hardcoded to "auth" since we don't use any other quop
     * @param {Response} res - the server CHALLENGE response
     * @returns {HttpHeaders}
     */
    private getAuthHeaderOnChallenge(res: HttpResponse<any>): HttpHeaders {

        let authHeader = res.headers.get('www-authenticate');
        let parsed = parseMultiPartString(authHeader);

        this.realm = parsed[0]['value'];
        this.nonce = parsed[1]['value'];

        const x = Math.floor(Math.random() * 100);
        const y = 0;

        /**
         * Compute sha256 hash of HA1:nonce:nc:cnonce:qop:HA2.
         * Example, sha256 hash of string aa71f01f351:dcd98b…bfb0c093:00000001:0a4f113b:auth:939e7552ac
         */

        let HA1 = DigestService.ha1(this.userName, this.realm, this.password);
        let HA2 = DigestService.ha2(this.requestedMethod, this.requestedUri);

        let response = sha256(`${HA1}:${this.nonce}:${x}:${y}:${this.cnonce}:auth:${HA2}`);

        /**
         * WARNING: keep this long string on single line, otherwise Chrome will fuck up this operation
         * @type {string}
         */
        let result = `Digest username="${this.userName}", realm="${this.realm}", nonce="${this.nonce}", uri="${this.requestedUri}", qop="auth", x=${x}, cnonce="${this.cnonce}", response="${response}"`;

        return new HttpHeaders({'Authorization': result});
    }

    /**
     * @description
     * Computes the HA1 component of the Authorization header. The computation receipt is as follows:
     * sha256 (username:realm:password)
     * @param userName
     * @param realm
     * @param password
     * @returns {string} containing sha256 hash
     */
    private static ha1(userName: string, realm: string, password: string): string {
        return sha256(`${userName}:${realm}:${password}`);
    }

    /**
     * @description
     * Computes the HA2 component of the Authorization header. The computation receipt is as follows:
     * sha256 (method:uri)
     * @param requestedMethod
     * @param requestedUri
     * @returns {string} containing sha256 hash
     */
    private static ha2(requestedMethod: string, requestedUri: string): string {
        return sha256(`${requestedMethod}:${requestedUri}`);
    }

    ngOnDestroy(): void {
        // todo: unsubscribe here via Subject
    }

}
