import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {DigestService} from "./digest.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {
    }

    handleError(error): void {
        const digestSvc : DigestService = this.injector.get(DigestService);
        if (error.status && error.status == 403) {
            digestSvc.logOut();
        }
        throw error;
    }

}