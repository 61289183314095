/**
 * @description
 * This service provides all common data fetching operations
 * Do not use for route specific fetching - e.g. product catalogue should fetch via it's own data service
 * to scale the file size of the JS
 */
import {Injectable} from "@angular/core";

@Injectable()
export class DataService {

    constructor() {}

}
