import {Component, ViewChild} from '@angular/core';
import {DataService} from "../../services/data.service";
import {ICulture, Translatable} from "../../interfaces/general";
import {LoginComponent} from "../login/login.component";
import {SettingsService} from "../../services/settings.service";
import {Router} from "@angular/router";
import {TimerService} from "../../services/timer.service";
import {DigestService} from "../../services/digest.service";
import {Subject} from "rxjs";
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app',
    templateUrl: '../../tpl/app.html'
})

export class AppComponent extends Translatable {

    @ViewChild(LoginComponent) loginComponent: LoginComponent;

    private _displayTime: string;
    get displayTime(): string {
        return this._displayTime;
    }

    set displayTime(value: string) {
        this._displayTime = value;
    }

    private _userName: string;
    get userName(): string {
        return this._userName;
    }

    set userName(value: string) {
        this._userName = value;
    }

    cultureOptions: ICulture[];
    selectedCulture: ICulture;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, public seSvc: SettingsService, public digestSvc: DigestService,
                private router: Router, private timSvc: TimerService) {
        super(dataSvc, seSvc);
        this.cultureOptions = seSvc.settings.cultures;
        this.selectedCulture = this.seSvc.culture;

        this.timSvc.timerTick
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((evt: string) => {
                this.displayTime = evt;
            });

        this.seSvc.cultureChanged
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => location.reload());
    }

    setCulture(id: number): void {
        this.selectedCulture = this.cultureOptions.filter(c => c.cultureId == id)[0];
        this.seSvc.setCulture(id);
    }

    logOut(): void {
        this.loginComponent.logOut();
        this.router.navigateByUrl('/pa').then(() => {})
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
